.Footer {
    justify-content: space-around;
    padding: 3rem 12rem;
    background-color: #383838;
    color: white;
}


.footer-text {
    font-family: 'Lora', serif;
    align-self: center;
}

.footer-logo {
    width: 16rem;
}