  .slider-container {
      width: 100%;
      overflow: hidden;
      border-radius: 1rem;
  }

  .carousel-img {
      width: 50rem;
      height: auto;
      padding: 1rem;
      border-radius: 2rem;
  }

  .carousel-cont {
      margin-right: 1.5rem !important;
  }