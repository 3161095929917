@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;600;700&family=Poppins:wght@400;600;700&display=swap');

body {
  overflow-x: hidden;
  background-color: #F5FEFF;
  scroll-behavior: smooth;
}

*,
html {

  scroll-behavior: smooth !important;
}

.flex {
  display: flex;
}

.flexRow {
  flex-direction: row;
}

.flexColumn {
  flex-direction: column;
}

.landing-scroll {
  display: none !important;
}