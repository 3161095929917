.Windows {
    background-color: #F5FEFF;
    padding: 5rem 12rem;
    text-align: center;
    margin-bottom: 5rem;
}

.Windows p {
    font-family: 'Poppins', sans-serif;
    color: #484848;
}

.windows-title-first {
    font-size: 42px;
    margin: 0;

    font-family: 'Lora', serif;
    color: #383838;
}

.windows-desc-first {
    font-family: 'Poppins', sans-serif;
    color: #484848;
    margin-top: 1rem;
}

.w-second-f,
.w-second-s {
    text-align: justify;
    margin: 0;
    margin-right: 1rem;
    margin-left: 1rem;
}


.windows-second {
    margin-top: 2rem;
    margin-bottom: 2rem;
    border: none;
    border-left: .2rem solid #c77840;
    border-right: .2rem solid #c77840;
    border-radius: .2rem;
}

.windows-third {
    margin-top: 2rem;
}


.windows-third-inner {
    margin-right: 3rem;
    justify-content: space-between;
}

.windows-third-inner-img {
    width: 50rem;
}

.windows-third-inner-img img {
    height: 27rem;
    border-radius: .5rem;
}

.w-side p {
    text-align: start;
    margin: 0;
    margin-top: .5rem;
}

.w-side-f {
    margin-right: 1rem !important;
}

.w-side-s {
    margin-left: 1rem !important;
}

.w-side-title {
    font-size: 20px;
    font-weight: 600;
    margin-top: 3rem;
    color: #c77840 !important;
    text-align: start;
}

.w-line {
    font-weight: 800;
    margin-right: .5rem;
    color: #c77840;
}

.w-side-main {
    text-align: start;
}

.w-third-last {
    text-align: start;
}

.w-img-bottom {
    margin-top: 5rem !important;
    width: 60%;
    text-align: center;
    margin: auto;
    font-style: italic;
}


.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #efefef;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.autoplay-progress {
    position: absolute;
    right: 16px;
    bottom: 16px;
    z-index: 10;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: #3d3d3d;
    background-color: #efefef;
    border-radius: 5rem;
}

.autoplay-progress svg {
    --progress: 0;
    position: absolute;
    left: 0;
    top: 0px;
    z-index: 10;
    width: 100%;
    height: 100%;
    stroke-width: 4px;
    stroke: #3d3d3d;
    fill: none;
    stroke-dashoffset: calc(125.6px * (1 - var(--progress)));
    stroke-dasharray: 125.6;
    transform: rotate(-90deg);
}

.swiper-pagination {
    width: fit-content !important;
    background-color: #efefef;
    border-radius: 1rem;
    padding: 0 .5rem;
    box-sizing: border-box;
    margin: 1rem;
    top: 0 !important;
    bottom: unset !important;
    box-shadow: #3d3d3d 0px 2px 5px 0px;
}

.swiper-pagination-bullet {
    background: #3d3d3d !important;
    margin: auto .5 !important;
}

.swiper-pagination-bullet-active {
    background: #3d3d3d !important;
}

.swiperGrid {
    height: 32rem;
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    gap: 16px;
}

/* Inner grid for column layout */
.swiperGridInner {
    display: flex;
    flex-direction: column;
    gap: 16px;
    /* Space between inner elements */
    width: 41%;
}

/* Image container */
.swiperImgContainer {
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Image styling */
.swiper-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.swiperGridWarp>.swiperImgContainer {
    flex: 1 1 calc(50% - 16px);
    aspect-ratio: 1;
    height: calc(50% - 8px);
}


.swiper-container-full {
    width: 100%;
}

.swiper-img-full-1 {
    width: unset;
    object-position: 0 75%;
}

.swiperGridWarp {
    flex-wrap: wrap;
    gap: 16px;
}

.swiper-img {
    border-radius: .5rem;
}