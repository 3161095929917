.Doors {
    background-color: #F5FEFF;
    padding: 5rem 12rem;
    text-align: center;
    margin-bottom: 5rem;
    font-family: 'Poppins', sans-serif;
}


.doors-features {
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.doors-features-inner {
    width: 25rem;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 5rem;
}

.doors-start-box>p {
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.doors-features-inner>p,
h5 {
    margin: 0;
}

.doors-features-inner>h5 {
    margin-bottom: .5rem;
    font-size: 20px;
    color: #c77840;
    font-family: 'Lora', serif;
}

.doors-benefits {
    justify-content: center;
    align-items: center;
    margin-bottom: 10rem;
}

.doors-benefits-inner {
    justify-content: space-between;
    width: 75%;
    margin-bottom: 2rem;
    transition: .3s ease-in-out;
}

.doors-benefits-inner>h5,
p {
    margin: 0;
    margin-top: 1rem;
}

.doors-benefits-inner>h5 {
    color: #c77840;
    font-family: 'Lora', serif;
    font-size: 22px;
    align-content: center;
    width: 30rem;
}

.doors-benefits-inner>p {
    width: 30rem;
}

.doors-benefits-hr {
    width: 100%;
    height: 1px;
    margin: auto 3rem;
    border-top: 3px solid #c77840;
}


.doors-img-box {
    height: 15rem;
    width: 100%;
    position: relative;
    background-color: black;
    border-radius: 1rem;
}

.doors-img-box>img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    opacity: .4;
    border-radius: 1rem;
    user-select: none;
    pointer-events: none;
}

.doors-img-inner {
    z-index: 5;
    margin: auto;
    text-align: center;
    transition: .3s ease-in-out;
}

.doors-img-inner>h5 {
    font-family: 'Lora', serif;
    font-size: 22px;
    color: #efefef;
    transition: .3s ease-in-out;
    user-select: none;
    pointer-events: none;
}

.doors-img-inner>p {
    align-self: center;
    font-size: 18px;
    color: #efefef;
    width: 70%;
    margin-top: .5rem;
    user-select: none;
    pointer-events: none;
}


.doors-img-box:hover .doors-img-inner {
    scale: 1.1;
    transition: .3s ease-in-out;
}

.doors-last>h5 {
    margin-bottom: .5rem;
    font-size: 24px;
    color: #c77840;
    font-family: 'Lora', serif;
}

.doors-last-p {
    margin-top: 5rem;
}