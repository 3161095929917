@media screen and (max-width: 1350px) {
  .nav-routes>a {
    margin: 0 0 0 2rem;
  }

  .nav-contact {
    margin: 0 0 0 4rem !important;
  }

  /* HOME */

  .home-cont {
    width: 55rem;
    justify-content: center;
  }

  /* ABOUT */
  .about-feature-box {
    padding: 2rem 4rem;
    flex: 0 0 45.333%;
  }

  .about-intro {
    width: 90%;
    margin: auto;
  }

  /* RECENTS */
  .Recents {
    padding: 5rem 6rem;
  }

  .carousel-img {
    width: 40rem;
    height: auto;
    padding: 1rem;
    border-radius: 2rem;
  }

  .carousel-cont {
    width: fit-content !important;
    margin-right: 1.5rem !important;
  }
}

@media screen and (max-width: 1250px) {
  .Navbar {
    padding: 0 3rem;
  }

  .Home {
    padding: 0 0 0 7rem;
  }

  .About {
    padding: 5rem 8rem;
  }

  .Contact {
    padding: 5rem 7rem;
  }

  .windows-third>div {
    flex-direction: column;
  }

  .windows-third-inner-img {
    margin-top: 2rem;
    width: 100%;
  }

  .doors-benefits-inner {
    width: 100%;
  }

  .doors-benefits {
    margin-bottom: 5rem !important;
  }

  .doors-features {
    margin-bottom: 0;
  }

  .p-h-feature-box {
    width: 100% !important;
  }

  .p-h-feature-box>h5 {
    text-align: center;
  }

  .breaker-icons {
    display: none;
  }

  .p-bottom>p {
    width: 85% !important;
  }

  .swiperGrid {
    height: 30rem;
  }

}

@media screen and (max-width: 1000px) {

  /* MENU */
  .menu-toggle {
    display: block;
  }

  .nav-routes {
    display: none;
  }

  /* HOME */
  .home-cont {
    width: unset;
  }

  .home-title {
    font-size: 75px;
  }

  .home-desc {
    font-size: 16px;
  }

  .home-quote {
    font-size: 16px;
  }

  /* ABOUT */
  .About {
    padding: 5rem 4rem;
  }

  .Windows {
    padding: 0rem 4rem;
  }

  .Doors {
    padding: 5rem 0rem;
  }

  .doors-start-box,
  .doors-features,
  .doors-last {
    padding: 0 4rem;
  }

  .doors-img-box,
  .doors-img-box>img {
    border-radius: 0;
  }



  .PassiveHouse {
    padding: 5rem 4rem !important;
  }

  .p-bottom>p {
    font-size: 18px !important;
  }

  .about-title {
    font-size: 52px;
  }

  .about-desc {
    font-size: 16px;
  }

  .about-feature-box {
    padding: 2rem 1rem;
  }

  /* RECENTS */

  .Recents {
    padding: 5rem 4rem;
  }

  .recent-title {
    font-size: 52px;
  }

  .carousel-img {
    width: 30rem;
    height: auto;
    padding: 1rem;
    border-radius: 2rem;
  }

  .carousel-cont {
    margin-right: 0.5rem !important;
  }

  /* CONTACT */

  .contact-title {
    font-size: 52px;
  }

  .contact-inner {
    flex-direction: column;
  }

  .quote {
    width: 20rem;
    align-self: center;
    margin-bottom: 8rem;
  }

  .contact-inner-2-box {
    margin-bottom: 4rem;
  }

  /* FOOTER */

  .Footer {
    flex-direction: column;
    align-items: center;
  }

  .footer-text {
    text-align: center;
    width: 18rem;
  }
}

@media screen and (max-width: 800px) {
  .home-links {
    display: none;
  }


  .swiperGrid {
    height: 24rem;
  }

  .windows-second {
    flex-direction: column;
    margin-top: 3rem;
  }

  .w-second-f {
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 700px) {
  .swiperGrid {
    height: 20rem;
  }
}

@media screen and (max-width: 600px) {
  .w-img-bottom {
    width: 80%;
  }

  .Windows {
    padding: 0 2rem !important;
  }

  .PassiveHouse {
    padding: 5rem 2rem !important;
  }

  .p-bottom>p {
    font-size: 16px !important;
  }

  .w-third-last {
    margin-top: 3rem;
  }
}

@media screen and (max-width: 560px) {

  /* NAVBAR */
  .Navbar {
    padding: 0 1rem;
  }

  .nav-title {
    font-size: 18px;
  }

  /* HOME */
  .Home {
    padding: 0 2rem;
  }

  .home-title {
    font-size: 40px;
  }

  .home-desc {
    width: unset;
    font-weight: unset;
  }

  /* ABOUT */
  .About {
    padding: 1rem 1rem;
  }

  .about-title {
    font-size: 30px;
  }

  .about-feature-box {
    padding: 0rem;
    flex: 0 0 90%;
    height: unset;
    margin-bottom: 8rem;
  }

  /* RECENTS */
  .Recents {
    padding: 0rem 0rem 4rem 0;
  }

  .recent-title {
    font-size: 30px;
  }

  .carousel-img {
    width: 15rem;
    height: auto;
    padding: 1rem;
    border-radius: 2rem;
  }

  .carousel-cont {
    margin-right: -1rem !important;
  }

  /* CONTACT */
  .Contact {
    padding: 1rem 1rem;
  }

  .contact-title {
    font-size: 30px;
  }

  .quote-title {
    font-size: 22px;
  }

  .contact-2-title {
    font-size: 22px;
  }

  .quote {
    width: 18rem;
  }

  /* FOOTER */
  .Footer {
    padding: 3rem 1rem;
  }


  /* MENU */
  .menu-toggle {
    width: 2.5rem;
    height: 2.5rem;
  }

  .home-title-first {
    font-size: 80px;
  }


  .swiperGrid {
    height: 15rem;
  }

  .w-side {
    flex-direction: column;
  }

  .w-side-f {
    margin-bottom: 1rem !important;
  }

  .w-side-s {
    margin: 0 !important;
  }

  .w-img-bottom {
    width: 100%;
  }

  .doors-img-inner>p {
    font-size: 16px;
  }

  .breaker-img-box {
    height: 20rem !important;
  }

  .p-breaker {
    margin: 5rem auto !important;
  }

  .windows-title-first {
    font-size: 30px;
  }
}

@media screen and (max-width: 450px) {

  .doors-last,
  .doors-features,
  .doors-start-box {
    padding: 0 2rem;
  }

  .p-h-feature-box {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .p-bottom {
    height: 25rem !important;
    bottom: -2.5rem !important;
  }

}

@media screen and (max-width: 400px) {
  .swiperGrid {
    height: 12rem !important;
  }
}

@media screen and (max-width: 376px) {
  .windows-third-inner {
    margin-right: 0 !important;
  }

  .breaker-img-box {
    height: 17rem !important;
  }
}



@media screen and (max-width: 350px) {
  .Home {
    padding: 0 1rem;
  }

  .home-title-first {
    font-size: 80px;
  }

  .home-title {
    font-size: 40px;
  }

  .quote {
    width: 100%;
  }

  .contact-info {
    width: 100%
  }

  .footer-text {
    text-align: center;
    width: 100%;
  }
}


@media screen and (max-width: 321px) {
  .swiperGrid {
    height: 10rem !important;
  }

  .doors-last,
  .doors-features,
  .doors-start-box {
    padding: 0 1rem;
  }

  .doors-img-inner>p {
    font-size: 15px;
  }

  .doors-img-inner>h5 {
    font-size: 20px;
  }

  .PassiveHouse {
    padding: 5rem 1rem !important;
  }

  .p-bottom {
    height: 28rem !important;
  }

}