.Navbar {
  background-color: rgba(16, 16, 16, 0.5);
  color: #ffffff;

  box-sizing: border-box;
  position: absolute;
  z-index: 10;

  height: 4rem;
  width: 100vw;
  padding: 0 10rem;

  font-family: "Lora", serif;

  justify-content: space-between;
  align-items: center;
}

.navbar-logo {
  width: 14rem;
}

.nav-title {
  font-size: 20px;
  font-weight: 600;
  align-self: center;
  margin-left: 1rem;
}

.nav-contact {
  color: #ffffff !important;
  margin: 0 0 0 8rem !important;
  font-weight: 600;
}

.nav-routes > a {
  /* background-color: rgb(31, 31, 31); */
  color: #cecece;
  text-decoration: none;

  font-size: 18px;

  padding: 0.5rem;
  margin: 0 0 0 4rem;
}

/* MOBILE DEVICES - MENU */

.menu-toggle {
  display: none;
  background: transparent;
  width: 3rem;
  height: 3rem;
  border: 0px solid transparent;
}

.menu-img {
  width: 100%;
}

.fullscreen-menu {
  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.836);

  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  z-index: 1000;

  transition: top 0.7s ease-in-out;
}

.fullscreen-menu > a {
  color: #cecece;
  /* background-color: red; */

  width: 100%;
  padding: 1rem;
  margin-bottom: 1rem;

  text-align: center;
  text-decoration: none;

  font-size: 18px;

  transition: 0.5s ease-in-out;
}

.fullscreen-menu > a:nth-last-child(1) {
  color: #ffffff !important;
  font-weight: 600;
}

.menu-logo {
  position: absolute;
  top: 2rem;
  width: 40%;
}

.fullscreen-menu.open {
  /* top: 0; */
  /* transform: translate(0, 100%); */
  top: 0;
}

.fullscreen-menu.closed {
  /* top: -250vw; */
  /* transform: translate(0, -100%); */
  top: -150%;
}

.close-menu {
  position: absolute;
  bottom: 3rem;
  left: 50%;
  transform: translate(-50%, 0);

  padding: 0.5rem;
  border-radius: 5rem;
  background: transparent;
  border: 0px solid transparent;
}
