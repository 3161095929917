.Home {
    position: relative;
    background-color: black;
    height: 100vh;
    box-sizing: border-box;
    justify-content: flex-start;
    padding: 0 0 0 10rem;
}

.scrolldown {
    user-select: none !important;
    pointer-events: none !important;
}

.Home .background-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../images/background.png');
    background-size: cover;
    background-position: center;
    opacity: 1;
    /* Adjust the opacity as needed */
    z-index: 0;
}

.Home .background-darken {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    /* Semi-transparent overlay */
    z-index: 1;
}

.home-cont {
    position: relative;
    z-index: 2;
    /* Ensure content is above the overlay */
}



.home-cont {
    width: 65rem;
    justify-content: center;
}

.home-title {
    font-family: 'Lora', serif;
    font-size: 84px;
    font-weight: 600;
    line-height: 1;
    margin: 0;
    color: #F5FEFF;
}

.home-desc {
    color: #dddddd;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    width: 85%;
}

.home-quote {
    background-color: #c77840;
    color: #F5FEFF;

    border-radius: 2rem;
    padding: 1rem 2rem;
    width: fit-content;

    text-decoration: none;
    font-size: 18px;
    font-weight: 600;

    font-family: 'Lora', serif;
    margin-top: 1rem;
}

.downlighted {
    color: #f5feffb2;
}

.home-title-first {
    font-size: 108px;
}


.home-links {
    margin: auto 0;
    z-index: 5;
    width: 20rem;
}

.home-scroll-link {
    width: 10rem;
    align-self: flex-end;
    margin: 0.5rem 0;
    font-size: 24px;
    text-align: start;
    text-decoration: none;
    color: #f5feffbe;
    font-family: 'Lora', serif;
    transition: .3s ease-in-out;
    background-color: #c77840cb;
    padding: 1rem 2.5rem 1rem 2rem;
    border-radius: 2rem 0 0 2rem;
}

.home-scroll-link:hover {
    background-color: #c77840;
    padding: 1rem 4rem 1rem 2rem;
}

.home-cont-upper {
    justify-content: space-between;
    width: 100%;
}

.landing-scroll {
    padding: 1rem 2rem;
    position: absolute;
    width: max-content;
    height: 5rem;

    bottom: -2rem;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 5;
}

.see-more {
    align-content: center;
    font-family: 'Poppins', sans-serif;
    padding-bottom: 1rem;
    color: #F5FEFF;
    font-weight: 600;
}