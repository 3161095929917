.Contact {
    box-sizing: border-box;
    background-color: #F5FEFF;
    padding: 5rem 12rem;
    height: auto;
    text-align: center;
}

.contact-title {
    font-family: 'Lora', serif;
    font-size: 64px;
    color: #383838;
    margin: 2rem 0 1rem 0;
}

.contact-inner {
    justify-content: space-around;
}

.quote-title {
    font-family: 'Lora', serif;
    font-size: 28px;
    color: #383838;
}

.contact-info {
    justify-content: space-between;
}

.contact-inner-2-box {
    align-items: center;
}

.contact-2-img {
    width: 50px;
    margin: 0;
}

.contact-2-title {
    margin: 0;
    font-family: 'Lora', serif;
    font-size: 28px;
    color: #383838;
}

.contact-2-small {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    color: #484848;
}