.quote-form-inner>p {
    text-align: start;
    font-family: 'Poppins', sans-serif;
    margin: 1rem 0 0 0;
    color: #484848;
}

.required {
    color: red;
    margin-left: .25rem;
    font-weight: 600;
}

.form-input-small {
    height: 2rem;
    border-radius: .5rem;
    border: 1px solid rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    padding: 0 .5rem;
    font-family: 'Poppins', sans-serif;
}

.form-textarea {
    border-radius: .5rem;
    font-family: 'Poppins', sans-serif;
    padding: .5rem .5rem;
    box-sizing: border-box;
}

.form-button {
    margin-top: 1rem;
    background-color: #D09163;
    width: 8rem;
    height: 2.5rem;
    font-family: 'Poppins', sans-serif;
    border-radius: .2rem;
    border: none;
    color: white;
    font-weight: 400;
    font-size: 15px;
    cursor: pointer;
}