.Recents {
    box-sizing: border-box;
    background-color: #F5FEFF;
    padding: 5rem 12rem;
    height: auto;
    text-align: center;
    margin-top: 5rem;
}

.recent-title {
    font-family: 'Lora', serif;
    font-size: 64px;
    color: #383838;
    margin: 2rem 0 1rem 0;
}