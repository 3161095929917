.Breaker {
    height: 48rem;
    position: relative;
    background-color: #F5FEFF;
    font-family: 'Poppins', sans-serif;
}

.p-breaker {
    width: 70%;
    justify-content: center;
    margin: 3rem auto;
}

.breaker-span {
    margin-right: 1rem;
    font-size: 20px;
    word-break: keep-all;
    width: max-content;
    font-weight: 600;
    color: #4b4b4b;
}

.breaker-img-box {
    height: 25rem;
}

.breaker-img-box>img {
    height: 100%;
}

.breaker-text {
    align-content: center;
}

.p-bottom {
    text-align: center;
}

.breaker-text {
    width: 35rem;
    flex-wrap: wrap;
}


.breaker-icons {
    height: fit-content;
    margin: auto 0;
    width: 50rem;
    flex-wrap: wrap;
    justify-content: space-between;
}

.icons-inner {
    align-items: center;
    margin: 0 1rem;
    height: fit-content;
    margin-bottom: 2rem;
    margin-top: 2rem;
}

.icons-img {
    height: 3rem;
    width: fit-content;
}

.icons-p {
    font-family: "Lora", serif;
    font-size: 20px;
    font-weight: 600;
    color: #c77840;
}