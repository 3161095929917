.PassiveHouse {
    background-color: #3d3d3d;
    color: #efefef !important;
    padding: 5rem 12rem;
    text-align: center;
    position: relative;
    font-family: 'Poppins', sans-serif;
}

.p-wave {
    position: absolute;
    top: -2.9rem;
    height: 3rem;
    left: 0;
    width: 100%;
    z-index: 1;
    /* background-color: red; */
}

.passive-house-features {
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 3rem;
    margin-bottom: 0;
    position: relative;
}

.passive-title {
    color: #efefef;
}

.p-h-feature-box {
    width: 30rem;
    text-align: justify;
    margin-right: 2rem;
    margin-left: 2rem;
    margin-bottom: 4rem;

    /* border: 1px solid #fdaa6f; */
    border-radius: .5rem;
    box-sizing: border-box;
    padding: 1rem;
}

.p-h-feature-box h5 {
    margin: 0;
    margin-bottom: .5rem;
    font-size: 18px;

    font-family: 'Lora', serif;
    color: #fdaa6f;
}


.p-h-feature-box p {
    margin: 0;
}


.p-bottom {
    position: absolute;
    bottom: 0;
    height: 20rem;
    background: black;
    width: 100%;
    left: 0;
}

.p-bottom>img {
    width: 100%;
    object-fit: cover;
    opacity: .3;
}

.p-bottom>p {

    position: absolute;
    z-index: 1;
    top: 45%;
    left: 50%;
    font-size: 20px;
    color: #efefef;
    font-weight: 600;
    width: 70%;
    transform: translate(-50%, -50%);
}

.p-wave-2 {

    position: absolute;
    height: 3rem;
    left: 0;
    bottom: -2.9rem;
    width: 100%;
    z-index: 5;
}
