.About {
    min-height: 100vh;
    background-color: #F5FEFF;

    padding: 5rem 12rem;
    text-align: center;
}

.about-intro {
    width: 70%;
    margin: auto;
}

.about-title {
    font-family: 'Lora', serif;
    font-size: 64px;
    color: #383838;
    margin: 2rem 0 0 0;
}

.about-desc {
    font-family: 'Poppins', sans-serif;
    color: #484848;
}


.about-features {
    margin-top: 7.5rem;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.about-feature-box {
    list-style: none;
    flex: 0 0 33.333%;

    margin-bottom: 3rem;

    box-sizing: border-box;
    padding: 2rem 5rem;
    height: 15rem;
}

/* SİL */
.feature-box-img {
    margin-bottom: 0.5rem;
    align-self: center;
    width: 2.5rem;
}

.feature-box-title {
    font-family: 'Lora', serif;
    color: #383838;
    font-size: 20px;
    margin: 0 0 .5rem 0;
    width: max-content;
    align-self: center;
}

.feature-box-desc {
    font-family: 'Poppins', sans-serif;
    color: #484848;
    font-size: 16px;
    margin: 0;
}